var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    _vm._l(_vm.tasks, function (task) {
      return _c(
        "v-card",
        { key: task.id, staticClass: "mb-2" },
        [
          _vm.status
            ? _c(
                "v-chip",
                {
                  staticClass: "chip-task-listing",
                  attrs: {
                    color: task.completed ? "success" : "red",
                    "text-color": "white",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(task.completed ? "COMPLETED" : "NOT COMPLETED") +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c("ParametersList", {
            attrs: {
              paramItems: _vm.getParamItems(task),
              groupTitle: task.task_id,
              vflexLength: "xs10",
              vflexOffset: "offset-xs1",
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }