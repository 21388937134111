var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading
        ? _c(
            "v-row",
            [_c("v-progress-linear", { attrs: { indeterminate: true } })],
            1
          )
        : _vm.isNotFound
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center",
                  attrs: { cols: "12", offset: "0" },
                },
                [
                  _c("p", [_vm._v("Document not found")]),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("Go Back")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", offset: "0" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "elevation-0",
                      attrs: {
                        color: "black",
                        "background-color": "submenu",
                        "slider-color": "black",
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _vm._l(_vm.tabsItems, function (tab) {
                        return _c("v-tab", {
                          key: tab.label,
                          attrs: { href: `#${tab.href}`, ripple: "" },
                          domProps: { textContent: _vm._s(tab.label) },
                        })
                      }),
                      _c("v-spacer"),
                      _vm.showHistoryComponent
                        ? _c("history-component", {
                            attrs: {
                              "doc-id": _vm.docId,
                              "module-name": _vm.moduleName,
                              "archived-confs-module-name":
                                _vm.archivedConfsModuleName,
                              email: _vm.updateInformation.updated_by,
                              "updated-date": _vm.updateInformation.update_date,
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.tabsItems, function (tab) {
                        return _c(
                          "v-tab-item",
                          { key: tab.label, attrs: { value: tab.href } },
                          [
                            _c(
                              tab.component.name,
                              _vm._b(
                                { tag: "component" },
                                "component",
                                tab.component.props,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }