
import { Component, Prop, Vue } from 'vue-property-decorator';
import importedComponents from './imported-components';
import { CONFIGURATIONS } from '@/constants/data-operations/status';

@Component({
	components: { ...importedComponents },
})
export default class ItemComponent extends Vue {
	@Prop({ required: true, type: String }) type!: string;
	@Prop({ required: true, type: Array }) tabsItems!: object[];
	@Prop({ required: true, type: Boolean }) isLoading!: boolean;
	@Prop({ required: true, type: Boolean }) isNotFound!: boolean;
	// Configuration Prop
	@Prop(String) docId?: string;
	@Prop(Object) updateInformation?: Object;
	@Prop(String) moduleName?: string;
	@Prop(String) archivedConfsModuleName?: string;

	activeTab: null = null;

	get showHistoryComponent() {
		return this.type === CONFIGURATIONS;
	}
}
