var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", { staticClass: "py-6", attrs: { fluid: "" } }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", size: "42", color: "primary" },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.hasError
              ? _c("div", [
                  _c("h2", [_vm._v("Path: " + _vm._s(_vm.path))]),
                  _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
                ])
              : Object.keys(_vm.logs).length === 1
              ? _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mb-4",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.downloadLogFile(Object.keys(_vm.logs)[0])
                          },
                        },
                      },
                      [_vm._v(" Download log file ")]
                    ),
                    _c("pre", { staticClass: "log pb-4" }, [
                      _vm._v(_vm._s(Object.values(_vm.logs)[0])),
                    ]),
                  ],
                  1
                )
              : _c(
                  "v-tabs",
                  {
                    staticClass: "elevation-1",
                    attrs: {
                      color: "black",
                      "background-color": "#E0E0E0",
                      "slider-color": "primary",
                    },
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _vm._l(Object.keys(_vm.logs), function (logKey) {
                      return _c("v-tab", {
                        key: logKey,
                        attrs: { href: `#${logKey}`, ripple: "" },
                        domProps: { textContent: _vm._s(logKey) },
                      })
                    }),
                    _vm._l(Object.keys(_vm.logs), function (logKey) {
                      return _c(
                        "v-tab-item",
                        { key: logKey, attrs: { value: logKey } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-4",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadLogFile(logKey)
                                },
                              },
                            },
                            [_vm._v("Download log file")]
                          ),
                          _c("pre", { staticClass: "log pb-4" }, [
                            _vm._v(_vm._s(_vm.logs[logKey])),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }