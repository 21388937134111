
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnyObject } from '@/types';
import { Base64 } from 'js-base64';

@Component
export default class LogsComponent extends Vue {
	isLoading: boolean = false;
	hasError: boolean = false;
	errorMsg: string = '';
	logs: AnyObject = {};
	activeTab: null = null;

	@Prop({ required: true }) private dagId!: string;
	@Prop({ required: true }) private dagRunId!: string;
	@Prop({ required: true }) private dagType!: string;
	@Prop({ required: true }) private dagExecutionDate!: string;
	@Prop(String) private taskId?: string;

	mounted() {
		this.getLogs();
	}

	getLogs(): void {
		let decodedLogFiles: AnyObject = [];

		this.isLoading = true;

		this.$httpsCallableFunction('fd-io-api-airflow-logs-manager', {
			dagId: this.dagId,
			taskId: this.taskId,
			dagRunId: this.dagRunId,
			dagType: this.dagType,
			dagExecutionDate: this.dagExecutionDate,
		})
			.then((res) => {
				const data = res.data;

				Object.keys(data).forEach((key) => {
					decodedLogFiles[key] = Base64.decode(data[key]);
				});

				this.logs = decodedLogFiles;
				this.isLoading = false;
			})
			.catch((err) => {
				this.hasError = true;
				this.errorMsg = err;
				this.isLoading = false;
			});
	}

	downloadLogFile(airflowFileName: string): void {
		const fileName = this.taskId
			? `${this.dagId}-${this.taskId}-${this.dagExecutionDate}-${airflowFileName}`
			: `${this.dagId}-${this.dagExecutionDate}-${airflowFileName}`;

		const element = document.createElement('a');
		element.setAttribute('href', `data:text/plain;charset=utf-8,${this.logs[airflowFileName]}`);
		element.setAttribute('download', fileName);
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	get path(): string {
		return this.taskId
			? `logs/${this.dagId}/${this.taskId}/${this.dagExecutionDate}`
			: `logs/${this.dagId}/${this.dagExecutionDate}`;
	}
}
