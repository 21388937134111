var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-progress-circular",
    {
      attrs: {
        rotate: 270,
        size: 35,
        value:
          _vm.jobsLength > 0
            ? (_vm.executedJobs.length / _vm.jobsLength) * 100
            : 0,
        color: "green",
      },
    },
    [_vm._v(" " + _vm._s(_vm.executedJobs.length) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }