
import { marked } from 'marked';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DocumentationTab extends Vue {
	@Prop({ type: String }) content?: string;

	get formattedContent() {
		return this.content ? marked.parse(this.content) : null;
	}
}
