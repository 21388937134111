
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnyObject, Doc } from '@/types';
import * as CollectionsListings from '@/components/data-operations/runs-collections';
import ParametersTable from '@/components/data-operations/common/item/parameters/ParametersTable.vue';

@Component({
	components: { ParametersTable },
})
export default class OtherRuns extends Vue {
	@Prop({ type: Object, required: true }) private doc!: Doc;
	@Prop({ type: String, required: true }) private moduleName!: Doc;

	get componentName(): string {
		const collectionsListings: AnyObject = CollectionsListings;
		return Object.values(collectionsListings).find((collection) => collection.moduleName === this.moduleName)
			.collectionListing;
	}
}
