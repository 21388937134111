
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnyObject, Configuration } from '@/types';
import { mapState } from 'vuex';
import AvatarComponent from '@/components/common/AvatarComponent.vue';
import store from '@/store';

@Component({
	components: { AvatarComponent },
	computed: {
		...mapState({
			archivedConfigurations(state: any) {
				return Object.values(state[this.archivedConfsModuleName].data as AnyObject).sort(
					(a, b) => Date.parse(b.update_date || b.updated_date) - Date.parse(a.update_date || a.updated_date)
				);
			},
		}),
	},
})
export default class HistoryComponent extends Vue {
	@Prop({ required: true }) moduleName!: string;
	@Prop({ required: true }) archivedConfsModuleName!: string;
	@Prop({ required: true }) docId!: string;
	@Prop({ required: true }) email!: string;
	@Prop({ required: true, default: 'No updated date' }) updatedDate!: string;

	showMenu: boolean = false;
	isLoading: boolean = false;
	isFetched: boolean = false;
	isUpdated: boolean = false;

	fetchArchivedConfs() {
		if (this.isFetched) return;

		this.isLoading = true;

		this.$store.dispatch(`${this.archivedConfsModuleName}/closeDBChannel`, { clearModule: true });
		this.$store
			.dispatch(`${this.archivedConfsModuleName}/fetchAndAdd`, {
				id: this.docId,
				where: [['account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id]],
			})
			.then(() => {
				this.isLoading = false;
				this.isFetched = true;
			});
	}

	getItemTitle(configuration: Configuration): string {
		return configuration.update_date || configuration.updated_date;
	}

	applyConfiguration(configuration: Configuration) {
		this.$store.commit(`${this.moduleName}/UPDATE_DOCUMENT_LOCALLY`, { id: this.docId, newDoc: configuration });
		this.isUpdated = true;
	}

	resetConfiguration() {
		this.isUpdated = false;
		store.dispatch(`${this.moduleName}/closeDBChannel`, { clearModule: true });
		store.dispatch(`${this.moduleName}/fetchById`, this.docId);
	}
}
