var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    [
      _vm.useMermaid
        ? _c("vue-mermaid-string", { attrs: { value: _vm.mermaidGraphData } })
        : _c("VueFlowy", { attrs: { chart: _vm.chart } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }