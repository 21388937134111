var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "mb-3" },
    [
      _c("ParametersList", {
        attrs: {
          groupTitle: _vm.task.id,
          tooltip: false,
          paramItems: _vm.paramItemsTask,
          vflexLength: "xs10",
          vflexOffset: "offset-xs1",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm.task.status
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "mr-3",
                        attrs: {
                          color: _vm.statusChipColor,
                          "text-color": "white",
                        },
                      },
                      [_vm._v(_vm._s(_vm.task.status))]
                    )
                  : _vm._e(),
                _vm.taskIcon
                  ? _c("v-icon", { attrs: { size: 32 } }, [
                      _vm._v(_vm._s(_vm.taskIcon)),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "", color: "transparent" } },
                  [
                    _c("v-spacer"),
                    _vm.showLogs
                      ? _c(
                          "v-dialog",
                          {
                            attrs: { width: "1000" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "p",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "cta mr-3 light-blue--text",
                                          },
                                          on
                                        ),
                                        [_vm._v("Logs")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2335868946
                            ),
                            model: {
                              value: _vm.logsDialog,
                              callback: function ($$v) {
                                _vm.logsDialog = $$v
                              },
                              expression: "logsDialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass: "text-h5 grey lighten-2",
                                    attrs: { "primary-title": "" },
                                  },
                                  [_vm._v("Task logs: " + _vm._s(_vm.task.id))]
                                ),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("logs-component", {
                                      staticClass: "px-0",
                                      attrs: {
                                        "dag-id": _vm.getLogsProps.dagId,
                                        "task-id": _vm.getLogsProps.taskId,
                                        "dag-run-id": _vm.getLogsProps.dagRunId,
                                        "dag-type": _vm.getLogsProps.dagType,
                                        "dag-execution-date":
                                          _vm.getLogsProps.dagExecutionDate,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.logsDialog = false
                                          },
                                        },
                                      },
                                      [_vm._v("Close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.task.task_type === "sql" &&
                    _vm.task.sql &&
                    _vm.task.table_name
                      ? _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "80%" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "p",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "cta mr-3 light-blue--text",
                                          },
                                          on
                                        ),
                                        [_vm._v("Preview data")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1835067199
                            ),
                            model: {
                              value: _vm.isPreviewDataDialogVisible,
                              callback: function ($$v) {
                                _vm.isPreviewDataDialogVisible = $$v
                              },
                              expression: "isPreviewDataDialogVisible",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "text-h5" },
                                  [
                                    _c("span", [_vm._v(_vm._s(_vm.task.id))]),
                                    _c("v-spacer"),
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.isPreviewDataDialogVisible = false
                                          },
                                        },
                                      },
                                      [_vm._v("close")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("v-spacer"),
                                    _vm.isFetchingPreviewData
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                indeterminate: "",
                                                size: "42",
                                                color: "primary",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm.hasFetchedPreviewData && !_vm.table
                                      ? [
                                          _c(
                                            "h2",
                                            { staticClass: "black--text" },
                                            [_vm._v("No content in this table")]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "v-tabs",
                                            {
                                              staticClass: "elevation-1",
                                              attrs: {
                                                color: "black",
                                                "background-color": "#E0E0E0",
                                                "slider-color": "primary",
                                              },
                                              model: {
                                                value:
                                                  _vm.previewDataDialogCurrentTab,
                                                callback: function ($$v) {
                                                  _vm.previewDataDialogCurrentTab =
                                                    $$v
                                                },
                                                expression:
                                                  "previewDataDialogCurrentTab",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.previewDataDialogTabs,
                                                function (tab) {
                                                  return _c(
                                                    "v-tab",
                                                    {
                                                      key: tab.href,
                                                      attrs: {
                                                        href: `#${tab.href}`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(tab.label) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c(
                                                "v-tab-item",
                                                {
                                                  attrs: {
                                                    value:
                                                      "general-information",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        tile: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c("v-data-table", {
                                                            attrs: {
                                                              headers:
                                                                _vm
                                                                  .generalInformationTable
                                                                  .headers,
                                                              items:
                                                                _vm
                                                                  .generalInformationTable
                                                                  .items,
                                                              "hide-default-footer":
                                                                "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item.total_source_table",
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "pre",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item
                                                                                .referenced_tables
                                                                                .length
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              4146455473
                                                            ),
                                                          }),
                                                          _c("ParametersList", {
                                                            attrs: {
                                                              groupTitle:
                                                                "Description",
                                                              tooltip: false,
                                                              paramItems:
                                                                _vm.descriptionItemsTask,
                                                              vflexLength:
                                                                "xs10",
                                                              vflexOffset:
                                                                "offset-xs1",
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "h2",
                                                                {
                                                                  staticClass:
                                                                    "black--text mt-10 mb-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Source Tables"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-data-table",
                                                                {
                                                                  attrs: {
                                                                    headers:
                                                                      _vm
                                                                        .sourceTablesTable
                                                                        .headers,
                                                                    items:
                                                                      _vm
                                                                        .sourceTablesTable
                                                                        .items,
                                                                    "hide-default-footer":
                                                                      "",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-tab-item",
                                                {
                                                  attrs: {
                                                    value: "data-overview",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        tile: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c("vue-good-table", {
                                                            attrs: {
                                                              columns:
                                                                _vm
                                                                  .dataIOverviewTableInformation
                                                                  .columns,
                                                              rows: _vm
                                                                .dataIOverviewTableInformation
                                                                .rows,
                                                              styleClass:
                                                                "vgt-table condensed striped",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "table-row",
                                                                  fn: function (
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-body-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              props
                                                                                .formattedRow[
                                                                                props
                                                                                  .column
                                                                                  .field
                                                                              ]
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              2545751082
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-tab-item",
                                                {
                                                  attrs: {
                                                    value: "table-schema",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        tile: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c(
                                                            "tableSchemaView",
                                                            {
                                                              attrs: {
                                                                schemaRows:
                                                                  _vm.schemaRows,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.task.task_type === "sql" && _vm.task.sql
                      ? _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "80%" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "p",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "cta mr-3 light-blue--text",
                                          },
                                          on
                                        ),
                                        [_vm._v("View SQL")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2247534662
                            ),
                            model: {
                              value: _vm.dialogSql,
                              callback: function ($$v) {
                                _vm.dialogSql = $$v
                              },
                              expression: "dialogSql",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between text-h5 grey lighten-2",
                                    attrs: { "primary-title": "" },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(_vm.task.id) + " "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          elevation: "2",
                                          icon: "",
                                          small: "",
                                        },
                                        on: { click: _vm.copySQLToClipboard },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { dark: "", small: "" } },
                                          [_vm._v("mdi-content-copy")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("prism", {
                                      attrs: {
                                        code: _vm.task.sql,
                                        plugins: ["line-numbers"],
                                        language: "sql",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogSql = false
                                          },
                                        },
                                      },
                                      [_vm._v("Close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.task.task_type === "create_gbq_table"
                      ? _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "80%" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "p",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "cta mr-3 light-blue--text",
                                          },
                                          on
                                        ),
                                        [_vm._v("View Schema")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3468926265
                            ),
                            model: {
                              value: _vm.dialogSchema,
                              callback: function ($$v) {
                                _vm.dialogSchema = $$v
                              },
                              expression: "dialogSchema",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass: "text-h5 grey lighten-2",
                                    attrs: { "primary-title": "" },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.task.id) + " ")]
                                ),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("tableSchemaView", {
                                      attrs: {
                                        schemaRows: _vm.task.bq_table_schema,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogSchema = false
                                          },
                                        },
                                      },
                                      [_vm._v("Close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-dialog",
                      {
                        attrs: { width: "1000" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "p",
                                  _vm._g(
                                    { staticClass: "cta light-blue--text" },
                                    on
                                  ),
                                  [_vm._v("Description")]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dialogLongDescription,
                          callback: function ($$v) {
                            _vm.dialogLongDescription = $$v
                          },
                          expression: "dialogLongDescription",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass: "text-h5 grey lighten-2",
                                attrs: { "primary-title": "" },
                              },
                              [_vm._v(" " + _vm._s(_vm.task.id) + " ")]
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c("vue-markdown", {
                                  attrs: { source: _vm.task.doc_md },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogLongDescription = false
                                      },
                                    },
                                  },
                                  [_vm._v("Close")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }