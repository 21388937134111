
import { Component, Vue, Prop } from 'vue-property-decorator';
import ViewJson from '@/components/data-operations/common/item/json/ViewJson.vue';
import Prism from 'vue-prismjs';
import 'prismjs/themes/prism.css';

export enum TaskListingExpandableType {
	Sql = 'sql',
	Pgp = 'pgp',
}

export interface TaskListingExpandableProps {
	name: string;
	type: TaskListingExpandableType;
	value: any;
}

@Component({
	components: { ViewJson, Prism },
})
export default class TaskListingExpandable extends Vue {
	@Prop({ type: Array, required: true }) tasks!: TaskListingExpandableProps[];

	get headers() {
		return [
			{
				text: 'Task Type',
				align: 'left',
				value: 'type',
				sortable: false,
			},
			{
				text: 'Task Params',
				align: 'left',
				value: 'name',
				sortable: false,
			},
		];
	}

	get TaskListingExpandableType() {
		return TaskListingExpandableType;
	}
}
