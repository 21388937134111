var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    attrs: { headers: _vm.headers, items: _vm.tasks, "show-expand": "" },
    scopedSlots: _vm._u([
      {
        key: "expanded-item",
        fn: function ({ headers, item }) {
          return [
            item.type === _vm.TaskListingExpandableType.Pgp
              ? _c("view-json", {
                  staticStyle: { "min-width": "max-content" },
                  attrs: { json: item, jsonId: item.value.task_id },
                })
              : item.type === _vm.TaskListingExpandableType.Sql
              ? _c(
                  "v-container",
                  [
                    _c("p", { staticClass: "text-h5 lighten-2" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("prism", {
                      attrs: {
                        code: item.value,
                        plugins: ["line-numbers"],
                        language: "sql",
                      },
                    }),
                  ],
                  1
                )
              : _c("v-container", [
                  _c("p", { staticClass: "text-h5 lighten-2" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("p", [_vm._v("Unsupported task type")]),
                ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }