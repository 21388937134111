var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center fill-height" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column text-right text-body-2" },
        [
          _c("span", { staticClass: "text--secondary" }, [
            _vm._v(_vm._s(_vm.email ? _vm.email : "No email")),
          ]),
          _c(
            "v-menu",
            {
              attrs: {
                transition: "slide-y-transition",
                "offset-y": "",
                "z-index": "10",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "span",
                        _vm._g(
                          {
                            staticClass: "cta",
                            class: {
                              "red--text": _vm.isUpdated,
                              "font-weight-bold": _vm.isUpdated,
                            },
                            on: { click: _vm.fetchArchivedConfs },
                          },
                          on
                        ),
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.updatedDate,
                                  "YYYY/MM/DD - HH:mm:ss"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.showMenu,
                callback: function ($$v) {
                  _vm.showMenu = $$v
                },
                expression: "showMenu",
              },
            },
            [
              _c(
                "v-list",
                { attrs: { "max-height": "40vh" } },
                [
                  _vm.isLoading
                    ? _c(
                        "v-list-item",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: "32",
                              color: "primary",
                            },
                          }),
                        ],
                        1
                      )
                    : Object.keys(_vm.archivedConfigurations).length > 0
                    ? [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.resetConfiguration } },
                          [
                            _c("v-list-item-title", [
                              _vm._v("Current Configuration"),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.archivedConfigurations,
                          function (configuration) {
                            return _c(
                              "v-list-item",
                              {
                                key: _vm.getItemTitle(configuration),
                                on: {
                                  click: function ($event) {
                                    return _vm.applyConfiguration(configuration)
                                  },
                                },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        _vm.getItemTitle(configuration),
                                        "YYYY/MM/DD - HH:mm:ss"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                      ]
                    : _c(
                        "v-list-item",
                        [
                          _c("v-list-item-title", [
                            _vm._v("No configuration history."),
                          ]),
                        ],
                        1
                      ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("avatar-component", {
        staticClass: "mx-2",
        attrs: { user: { email: _vm.email } },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }