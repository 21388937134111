import AvatarComponent from '@/components/common/AvatarComponent.vue';
import HistoryComponent from '@/components/data-operations/common/item/HistoryComponent.vue';
import LogsComponent from '@/components/data-operations/common/item/logs/LogsComponent.vue';
import OtherRuns from './other-runs/OtherRuns.vue';
import OverviewComponent from './overview/OverviewComponent.vue';
import TableSchemaView from '@/components/data-operations/common/item/schema/TableSchemaView.vue';
import TaskListing from '@/components/data-operations/common/item/tasks/TaskListing.vue';
import TaskListingLegacy from '@/components/data-operations/common/item/tasks/TaskListingLegacy.vue';
import TaskListingExpandable from '@/components/data-operations/common/item/tasks/TaskListingExpandable.vue';
import ViewJson from './json/ViewJson.vue';
import NotesTab from './notes/NotesTab.vue';
import DocumentationTab from './DocumentationTab.vue';

export default {
	AvatarComponent,
	HistoryComponent,
	LogsComponent,
	OverviewComponent,
	OtherRuns,
	TableSchemaView,
	TaskListing,
	TaskListingLegacy,
	TaskListingExpandable,
	ViewJson,
	NotesTab,
	DocumentationTab,
};
