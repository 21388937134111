var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-center" },
    [
      _c(_vm.componentName, {
        tag: "component",
        attrs: { isOtherRunDisplay: "", "job-id": _vm.doc.job_id },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }