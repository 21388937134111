var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "blue" },
          })
        : _vm._l(_vm.tasksFull, function (task) {
            return _c(
              "div",
              { key: task.id },
              [
                _c("task-item", {
                  attrs: {
                    type: _vm.type,
                    task: task,
                    dagContext: _vm.dagContext,
                    "dag-id": _vm.dagId,
                    "dag-run-id": _vm.dagRunId,
                    "dag-type": _vm.dagType,
                    "dag-execution-date": _vm.dagExecutionDate,
                  },
                }),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }