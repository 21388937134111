
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataItem } from '@/types';
import DagChart from '@/components/data-operations/common/item/overview/DagChart.vue';
import ParametersList from '../parameters/ParametersList.vue';
import ParametersTable from '../parameters/ParametersTable.vue';
import ViewHeader from './ViewHeader.vue';

@Component({
	components: { DagChart, ParametersList, ParametersTable, ViewHeader },
})
export default class OverviewComponent extends Vue {
	@Prop({ type: Array, required: true }) data!: DataItem[];
}
