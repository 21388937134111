
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AnyObject, Snackbar } from '@/types';
import ConfigurationStatus from '@/components/data-operations/common/configuration/ConfigurationStatus.vue';
import JobsRatio from '@/components/data-operations/common/status/jobs-ratio.vue';
import RunStatusChip from '@/components/data-operations/common/runs/RunStatusChip.vue';
import { Api } from '@/constants/api';
import { CONFIGURATIONS } from '@/constants/data-operations/status';
import { DATA_OPERATIONS } from '@/constants/router/paths-prefixes';
import { SNACKBAR } from '@/constants/ui/snackbar';

// TODO: Refactor viewType possible values with constants
// TODO: Refactor by removing headerActive prop to be based on viewType

@Component({
	components: { ConfigurationStatus, JobsRatio, RunStatusChip },
})
export default class ViewHeader extends Vue {
	@Prop({ required: true }) readonly activeHeader!: boolean;
	@Prop({ required: true }) readonly viewId!: string;
	@Prop({ required: true }) readonly viewType!: string;
	@Prop({ required: true }) readonly item!: AnyObject;
	@Prop(Boolean) readonly showDagLaunch?: boolean;
	@Prop(String) readonly collection?: string;
	@Prop(String) readonly runStatus?: string;
	@Prop(String) readonly description?: string;

	@Getter('user/isViewer') isViewer!: boolean;
	@Getter('user/isSuperAdmin') isSuperAdmin!: boolean;

	isLoading = false;
	isResettingWorkflow = false;
	showDagLaunchDialog = false;
	launchSnackBar: Snackbar = {
		color: '',
		isVisible: false,
		text: '',
		timeout: SNACKBAR.TIMEOUT,
	};
	resetWorkflowStatusSnackBar: Snackbar = {
		color: 'error',
		isVisible: false,
		text: 'Error while resetting workflow',
		timeout: SNACKBAR.TIMEOUT,
	};
	isArchiveDialogVisible = false;
	showExecutionDateParams = false;
	dagExecutionDay = moment().format('YYYY-MM-DD');
	showDagExecutionDayMenu = false;
	dagExecutionTime = '00:00';
	showDagExecutionTimeMenu = false;
	dagLaunchErrorMsg = '';

	goBack() {
		this.$router.go(-1);
	}

	toggleDagLaunchDialog() {
		this.showDagLaunchDialog = !this.showDagLaunchDialog;
	}

	async launchDag() {
		this.isLoading = true;

		let data: AnyObject = {
			action: 'launch_job',
			environment: this.item.environment,
			configuration_type: this.item.configuration_type,
			configuration_id: this.item.configuration_id,
		};

		if (this.showExecutionDateParams && this.dagExecutionDay && this.dagExecutionTime) {
			data.execution_date = this.$moment(
				`${this.dagExecutionDay} ${this.dagExecutionTime}`,
				'YYYY-MM-DD hh:mm:ss'
			).format();
		}

		if (this.dagLaunchErrorMsg.length > 0) this.dagLaunchErrorMsg = '';

		try {
			await this.$post(Api.Dag.Launch, data);
			this.toggleDagLaunchDialog();
			this.launchSnackBar.isVisible = true;
			this.launchSnackBar.color = 'success';
			this.launchSnackBar.text = 'Data Operation Launched';
		} catch (err) {
			this.dagLaunchErrorMsg = err;
			this.launchSnackBar.isVisible = true;
		} finally {
			this.isLoading = false;
		}
	}

	showArchiveDialog() {
		this.isArchiveDialogVisible = true;
	}

	hideArchiveDialog() {
		this.isArchiveDialogVisible = false;
	}

	toggleArchiveConf() {
		const payload = this.item.archived
			? { id: this.item.id, archived: !this.item.archived }
			: { id: this.item.id, activated: false, archived: !this.item.archived };

		this.isLoading = true;

		this.$store.dispatch(`${this.collection}/patch`, payload).then(() => {
			this.hideArchiveDialog();
			this.isLoading = false;
		});
	}

	redirectToConfiguration() {
		this.$router.push(this.link);
	}

	async resetWorkflowStatus() {
		this.isResettingWorkflow = true;
		try {
			const { account, environment, id } = this.item;
			await this.$httpsCallableFunction('tailer-api-workflow-status', {
				action: 'reset_workflow_status',
				account,
				environment,
				configuration_type: 'workflow',
				configuration_id: id,
			});
			location.reload();
		} catch {
			this.resetWorkflowStatusSnackBar.isVisible = true;
		}
		this.isResettingWorkflow = false;
	}

	get archiveLabel(): string {
		return this.item.archived ? 'Unarchive' : 'Archive';
	}

	get link(): string {
		const workflowType = this.$route.path.split('/')[2];
		const docType = this.$route.path.split('/')[3];

		if (this.viewType !== 'conf' || docType === CONFIGURATIONS) return '';
		return `/${DATA_OPERATIONS}/${workflowType}/${CONFIGURATIONS}/${this.viewId}`;
	}
}
