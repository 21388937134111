var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", offset: "0" } },
            [
              _vm._l(_vm.data, function (item, index) {
                return [
                  !item.hasOwnProperty("displayCondition") ||
                  item.displayCondition
                    ? _c(
                        item.component,
                        _vm._b(
                          {
                            key: `${item.component}-${index}`,
                            tag: "component",
                          },
                          "component",
                          item.props,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }