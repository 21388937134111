
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AnyObject } from '@/types';
import Prism from 'vue-prismjs';
import VueMarkdown from 'vue-markdown';

import ParametersList from '@/components/data-operations/common/item/parameters/ParametersList.vue';
import tableSchemaView from '@/components/data-operations/common/item/schema/TableSchemaView.vue';
import LogsComponent from '@/components/data-operations/common/item/logs/LogsComponent.vue';

import { CONFIGURATIONS, RUNS } from '@/constants/data-operations/status';

import 'prismjs/themes/prism.css';
import 'vue-good-table/dist/vue-good-table.css';

import { getFormattedNumBytes, getFormattedNumRows } from '@/util/data-models';

import { mdiTablePlus, mdiTableMultiple, mdiTableCog } from '@mdi/js';

type CommunParam = {
	id: string;
	label: string;
	value: string;
	default: string | boolean;
	description: string;
};

@Component({
	components: {
		LogsComponent,
		ParametersList,
		VueMarkdown,
		Prism,
		tableSchemaView,
		'vue-good-table': require('vue-good-table').VueGoodTable,
	},
})
export default class TaskItem extends Vue {
	@Prop({ type: String, required: true }) type!: string;
	@Prop({ type: Object, required: true }) task!: AnyObject;
	@Prop({ type: Object, required: true }) dagContext!: AnyObject;
	@Prop({ type: String, required: true }) dagId!: string;
	@Prop({ type: String, required: true }) dagRunId!: string;
	@Prop({ type: String, required: true }) dagType!: string;
	@Prop({ type: String, required: true }) dagExecutionDate!: string;

	isPreviewDataDialogVisible = false;
	isFetchingPreviewData = false;
	hasFetchedPreviewData = false;
	previewDataDialogCurrentTab: null = null;
	table: null | AnyObject = null;
	dialogSql: boolean = false;
	dialogSchema: boolean = false;
	dialogLongDescription: boolean = false;
	logsDialog: boolean = false;

	@Watch('isPreviewDataDialogVisible')
	async onIsPreviewDataDialogVisibleChanged(isPreviewDataDialogVisible: boolean) {
		if (isPreviewDataDialogVisible && !this.hasFetchedPreviewData) {
			this.isFetchingPreviewData = true;

			const projectId = this.task.gcp_project_id ?? this.dagContext.default_gcp_project_id;
			const datasetId = this.task.bq_dataset ?? this.dagContext.default_bq_dataset;
			const tableId = this.task.table_name;
			const limit = 0;

			if (this.type === RUNS) {
				const querySnapshot = await this.$store.dispatch('taskRunDataPreview/fetch', {
					projectId,
					datasetId,
					tableId,
					where: [
						['account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id],
						['dag_run_id', '==', this.dagRunId],
					],
					limit,
				});

				const docsLength = querySnapshot.docs.length;
				if (docsLength > 0) {
					this.table = querySnapshot?.docs[docsLength - 1].data();
				}
			}

			if (this.type === CONFIGURATIONS || (this.type === RUNS && !this.table)) {
				const table = await this.$store.dispatch('dataTableDetails/fetchAndAdd', {
					projectId,
					datasetId,
					tableId,
					where: [['account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id]],
					limit: 0,
				});
				console.log('Conf table', table);
				this.table = table;
			}

			this.hasFetchedPreviewData = true;
			this.isFetchingPreviewData = false;
		}
	}

	/*async getFirestoreData() {
		await store.dispatch('dataTableDetails/closeDBChannel', {
			clearModule: true,
		});
		await store.dispatch('dataTableDetails/fetchAndAdd', {
			projectId: this.projectId,
			datasetId: this.datasetId,
			tableId: this.tableId,
			limit: 0,
		});
	}*/

	copySQLToClipboard() {
		navigator.clipboard.writeText(this.task.sql);
	}

	get paramItemsTask() {
		let communParams: CommunParam[] = [
			{
				id: 'short_description',
				label: 'Short Description',
				value: this.task.short_description,
				default: 'Id of the Task',
				description: 'Unique Id of the task in the dag ',
			},
			{
				id: 'task_type',
				label: 'Task Type',
				value: this.task.task_type,
				default: 'SQL',
				description: 'The type of the task (execute SQL or create Table',
			},
		];

		if (this.task.task_type === 'sql') {
			communParams.push({
				id: 'gcp_project_id',
				label: 'Table Project',
				value: this.task.gcp_project_id,
				default: this.dagContext.default_gcp_project_id,
				description: 'Project of the destination table (surcharge the default project at the dag level configuration)',
			});
			communParams.push({
				id: 'bq_dataset',
				label: 'Table Dataset',
				value: this.task.bq_dataset,
				default: this.dagContext.default_bq_dataset,
				description: 'Dataset of the destination table (surcharge the default dataset at the dag level configuration)',
			});
			if (this.task.table_name) {
				communParams.push({
					id: 'table_name',
					label: 'Table Name',
					value: this.task.table_name,
					default: 'my_table',
					description: 'Name of the destination table generated by the SQL Query',
				});
			}
			communParams.push({
				id: 'temporary_table',
				label: 'Temporary Table',
				value: this.task.temporary_table,
				default: false,
				description: "Define if it's a temporary table that will be deleted at the end of the Dag",
			});
			communParams.push({
				id: 'sql_file',
				label: 'Name of the SQL File',
				value: this.task.sql_file,
				default: 'my_sql_file.sql',
				description: 'Name of the .sql file with the query generating the destination table',
			});
		}

		if (this.task.task_type === 'copy_gbq_table') {
			communParams.push({
				id: 'gcp_project_id',
				label: 'Table Project',
				value: this.task.gcp_project_id,
				default: this.dagContext.default_gcp_project_id,
				description: 'Project of the destination table (surcharge the default project at the dag level configuration)',
			});
			communParams.push({
				id: 'bq_dataset',
				label: 'Table Dataset',
				value: this.task.bq_dataset,
				default: this.dagContext.default_bq_dataset,
				description: 'Dataset of the destination table (surcharge the default dataset at the dag level configuration)',
			});
			if (this.task.destination_bq_table) {
				communParams.push({
					id: 'destination_bq_table',
					label: 'Destination table Name',
					value: this.task.destination_bq_table,
					default: 'my_table',
					description: 'Name of the destination table copied from source table',
				});
			}
			communParams.push({
				id: 'destination_bq_table_date_suffix',
				label: 'Add Date suffix to destination table',
				value: this.task.destination_bq_table_date_suffix,
				default: false,
				description: 'Add the current date as a suffix to the destination table',
			});
			communParams.push({
				id: 'destination_bq_table_date_suffix_format',
				label: 'Date suffix format',
				value: this.task.destination_bq_table_date_suffix_format,
				default: '%Y%m%d',
				description: 'Format of the destination table date Suffix',
			});
		}

		return communParams;
	}

	get showLogs() {
		return this.type === RUNS;
	}

	get statusChipColor(): string {
		let color;

		switch (this.task.status) {
			case 'success':
				color = 'success';
				break;
			default:
				color = 'primary';
				break;
		}

		return color;
	}

	get taskIcon() {
		switch (this.task.task_type) {
			case 'create_gbq_table':
				return mdiTablePlus;
			case 'copy_gbq_table':
				return mdiTableMultiple;
			case 'sql':
				return mdiTableCog;
			default:
				return '';
		}
	}

	get getLogsProps() {
		return {
			dagId: this.dagId,
			taskId: this.task.id,
			dagRunId: this.dagRunId,
			dagType: this.dagType,
			dagExecutionDate: this.dagExecutionDate,
		};
	}

	get previewDataDialogTabs(): { label: string; href: string }[] {
		return [
			{ label: 'General Information', href: 'general-information' },
			{ label: 'Data Overview', href: 'data-overview' },
			{ label: 'Table Schema', href: 'table-schema' },
		];
	}

	get generalInformationTable() {
		const headers = [
			{ text: 'Update Duration', value: 'job_duration' },
			{ text: 'Bytes Processed', value: 'total_bytes_processed' },
			{ text: 'Bytes Billed', value: 'total_bytes_billed' },
			{ text: 'Slot Millis', value: 'slot_millis' },
			{ text: 'Total Source Tables', value: 'total_source_table' },
		];
		const items = this.table?.update_infos ?? [];
		return { headers, items };
	}

	get descriptionItemsTask(): CommunParam[] {
		return [
			{
				id: 'numRows',
				label: 'Row number',
				value: getFormattedNumRows(Number(this.table?.numRows ?? 0)),
				default: '-',
				description: 'Number of rows',
			},
			{
				id: 'numBytes',
				label: 'Table size',
				value: getFormattedNumBytes(Number(this.table?.numBytes ?? 0)),
				default: '-',
				description: 'Size of the table',
			},
			{
				id: 'timePartitioning',
				label: 'Time partitioned',
				value: `${Boolean(this.table?.timePartitioning ?? false)}`,
				default: '-',
				description: 'Is partitioned',
			},
			{
				id: 'short_description',
				label: 'Temporary table',
				value: this.table?.temporary_table,
				default: '-',
				description: 'Is partitioned',
			},
			{
				id: 'location',
				label: 'Location',
				value: this.table?.location,
				default: '-',
				description: 'Location of the table',
			},
		];
	}

	get dataIOverviewTableInformation() {
		const rows: string[] = Object.values(this.table?.json ?? {});
		return {
			columns: rows.length > 0 ? Object.keys(rows[0]).map((data: string) => ({ label: data, field: data })) : [],
			rows,
		};
	}

	get schemaRows() {
		return this.table?.schema.fields ?? [];
	}

	get sourceTablesTable() {
		const headers = [
			{ text: 'Project ID', value: 'project_id' },
			{ text: 'Dataset', value: 'dataset_id' },
			{ text: 'Table', value: 'table_id' },
		];
		const items = this.table
			? this.table?.update_infos
					.map((updateInfo: AnyObject) => {
						const infos = [];
						for (const referencedTable of updateInfo.referenced_tables) {
							infos.push({
								project_id: referencedTable.project_id,
								dataset_id: referencedTable.dataset_id,
								table_id: referencedTable.table_id,
							});
						}
						return infos;
					})
					.flat()
			: [];
		return { headers, items };
	}
}
