
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AnyObject } from '@/types';
import ParametersList from '@/components/data-operations/common/item/parameters/ParametersList.vue';

@Component({
	components: { ParametersList },
})
export default class TaskListing extends Vue {
	@Prop({ type: Array, required: true }) tasks!: AnyObject[];
	@Prop({ type: Array, required: false }) valuesToDisplay!: string[];
	@Prop({ type: Boolean, required: false, default: false }) status!: boolean;

	getParamItems(task: AnyObject): object {
		if (this.valuesToDisplay) {
			const results = [];
			for (const value of this.valuesToDisplay) {
				results.push({
					id: value,
					label: this.paramLabel[value] ? this.paramLabel[value] : '!! error label !!',
					value: task[value],
				});
				if (value === 'sql_query') {
					const index = results.findIndex((item) => item.id === 'sql_query');
					results[index] = {
						...results[index],
						component: 'sql-viewer',
						properties: {
							id: task.task_id,
							sql: task.sql_query,
						},
					};
				}
			}
			return results;
		}
		return Object.entries(task)
			.filter(([key]) => key !== 'sql')
			.map(([key, value]) => {
				let item = {
					id: key,
					label: this.paramLabel[key] ? this.paramLabel[key] : '!! error label !!',
				};
				if (key === 'sql_file') {
					let base64Decoded;
					try {
						base64Decoded = Base64.decode(task.sql);
					} catch (e) {
						base64Decoded = Base64.decode(task.sql.toBase64());
					}
					item['component'] = 'sql-viewer';
					item['properties'] = {
						id: task.sql_file,
						sql: task.sql ? base64Decoded : null,
					};
				} else {
					item['value'] = value === false ? 'false' : value;
				}
				return item;
			});
	}

	get paramLabel(): object {
		return {
			task_id: 'Task ID',
			gcs_dest_bucket: 'GCS Dest Bucket',
			gcs_dest_prefix: 'GCS Dest Prefix',
			gcp_project_id: 'GCP Project ID',
			field_delimiter: 'Field Delimiter',
			compression: 'Compression',
			sql_file: 'SQL',
			output_filename: 'Output Filename',
			destination_format: 'Destination Format',
			sql_query_template: 'SQL Query Template',
			generate_top_file: 'Generate Top File',
			delete_dest_bucket_content: 'Delete Dest Bucket',
			copy_table: 'Copy Table',
			dest_gcp_project_id: 'Dest GCP Project ID',
			dest_gbq_dataset: 'Dest GBQ Dataset',
			dest_gbq_table: 'Dest GBQ Table',
			dest_gbq_table_suffix: 'Dest GBQ Table Suffix',
			completed: 'Completed',
			table_name: 'Table Name',
			message: 'Message',
			sql_query: 'SQL Query',
		};
	}
}
