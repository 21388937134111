var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.item.archived
        ? _c("v-alert", { attrs: { type: "warning" } }, [
            _vm._v("This configuration is archived."),
          ])
        : _vm._e(),
      _vm.activeHeader
        ? _c(
            "v-row",
            { staticClass: "pl-5 pt-4 pr-5" },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-h5 font-weight-black text-truncate complementary--text",
                },
                [_vm._v(_vm._s(_vm.viewId))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "grey" },
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v("Go Back")]
              ),
              _vm.viewType === "conf" && !_vm.isViewer
                ? [
                    _c("configuration-status", {
                      staticClass: "mr-3",
                      attrs: {
                        item: _vm.item,
                        collection: _vm.collection,
                        "is-activated": _vm.item.activated,
                        "is-small": false,
                        "is-label": true,
                      },
                    }),
                    _vm.showDagLaunch
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "mr-3 text-uppercase",
                            attrs: {
                              color: "menu",
                              "text-color": "white",
                              label: "",
                            },
                            on: { click: _vm.toggleDagLaunchDialog },
                          },
                          [_vm._v(" Launch ")]
                        )
                      : _vm._e(),
                    !_vm.item.archived || _vm.isSuperAdmin
                      ? _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c("v-icon", _vm._g({}, on), [
                                        _vm._v("more_vert"),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              892639569
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.item.archived
                                          ? _vm.toggleArchiveConf()
                                          : _vm.showArchiveDialog()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(_vm.archiveLabel)),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-dialog",
                      {
                        attrs: { persistent: "", "max-width": "700" },
                        model: {
                          value: _vm.showDagLaunchDialog,
                          callback: function ($$v) {
                            _vm.showDagLaunchDialog = $$v
                          },
                          expression: "showDagLaunchDialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "text-h5 primary" },
                              [_vm._v("Confirmation")]
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c("p", { staticClass: "mt-4" }, [
                                  _vm._v(" Please, confirm the launch of "),
                                  _c("strong", [
                                    _vm._v('"' + _vm._s(_vm.viewId) + '"'),
                                  ]),
                                  _vm._v(" configuration. "),
                                ]),
                                _c("v-switch", {
                                  attrs: {
                                    label: "Specify the execution date",
                                  },
                                  model: {
                                    value: _vm.showExecutionDateParams,
                                    callback: function ($$v) {
                                      _vm.showExecutionDateParams = $$v
                                    },
                                    expression: "showExecutionDateParams",
                                  },
                                }),
                                _vm.showExecutionDateParams
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  "close-on-content-click": false,
                                                  transition:
                                                    "scale-transition",
                                                  "offset-y": "",
                                                  "max-width": "290px",
                                                  "min-width": "290px",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  readonly: "",
                                                                  label: "Day",
                                                                  "persistent-hint":
                                                                    "",
                                                                  "prepend-icon":
                                                                    "event",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dagExecutionDay,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.dagExecutionDay =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "dagExecutionDay",
                                                                },
                                                              },
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  765968504
                                                ),
                                                model: {
                                                  value:
                                                    _vm.showDagExecutionDayMenu,
                                                  callback: function ($$v) {
                                                    _vm.showDagExecutionDayMenu =
                                                      $$v
                                                  },
                                                  expression:
                                                    "showDagExecutionDayMenu",
                                                },
                                              },
                                              [
                                                _c("v-date-picker", {
                                                  attrs: { "no-title": "" },
                                                  on: {
                                                    input: function ($event) {
                                                      _vm.showDagExecutionDayMenu = false
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.dagExecutionDay,
                                                    callback: function ($$v) {
                                                      _vm.dagExecutionDay = $$v
                                                    },
                                                    expression:
                                                      "dagExecutionDay",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                ref: "menu",
                                                attrs: {
                                                  readonly: "",
                                                  "close-on-content-click": false,
                                                  "nudge-right": 40,
                                                  "return-value":
                                                    _vm.dagExecutionTime,
                                                  transition:
                                                    "scale-transition",
                                                  "offset-y": "",
                                                  "max-width": "290px",
                                                  "min-width": "290px",
                                                },
                                                on: {
                                                  "update:returnValue":
                                                    function ($event) {
                                                      _vm.dagExecutionTime =
                                                        $event
                                                    },
                                                  "update:return-value":
                                                    function ($event) {
                                                      _vm.dagExecutionTime =
                                                        $event
                                                    },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  label: "Time",
                                                                  "prepend-icon":
                                                                    "access_time",
                                                                  readonly: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dagExecutionTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.dagExecutionTime =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "dagExecutionTime",
                                                                },
                                                              },
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1724851007
                                                ),
                                                model: {
                                                  value:
                                                    _vm.showDagExecutionTimeMenu,
                                                  callback: function ($$v) {
                                                    _vm.showDagExecutionTimeMenu =
                                                      $$v
                                                  },
                                                  expression:
                                                    "showDagExecutionTimeMenu",
                                                },
                                              },
                                              [
                                                _c("v-time-picker", {
                                                  attrs: { "full-width": "" },
                                                  on: {
                                                    "click:minute": function (
                                                      $event
                                                    ) {
                                                      return _vm.$refs.menu.save(
                                                        _vm.dagExecutionTime
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.dagExecutionTime,
                                                    callback: function ($$v) {
                                                      _vm.dagExecutionTime = $$v
                                                    },
                                                    expression:
                                                      "dagExecutionTime",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dagLaunchErrorMsg
                                  ? _c("p", { staticClass: "red--text" }, [
                                      _vm._v(_vm._s(_vm.dagLaunchErrorMsg)),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: { click: _vm.toggleDagLaunchDialog },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "transition",
                                  { attrs: { name: "fade", mode: "out-in" } },
                                  [
                                    _vm.isLoading
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            size: "20",
                                            color: "primary",
                                          },
                                        })
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: { click: _vm.launchDag },
                                          },
                                          [_vm._v("Launch")]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { persistent: "", "max-width": "400" },
                        model: {
                          value: _vm.isArchiveDialogVisible,
                          callback: function ($$v) {
                            _vm.isArchiveDialogVisible = $$v
                          },
                          expression: "isArchiveDialogVisible",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "text-h5 warning" },
                              [_vm._v("Warning")]
                            ),
                            _c("v-card-text", [
                              _c("p", { staticClass: "mt-4" }, [
                                _vm._v(" Please confirm the archiving of "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.item.id) + "."),
                                ]),
                              ]),
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: { click: _vm.hideArchiveDialog },
                                  },
                                  [_vm._v("Close")]
                                ),
                                _c(
                                  "transition",
                                  { attrs: { name: "fade", mode: "out-in" } },
                                  [
                                    _vm.isLoading
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            size: "20",
                                            color: "primary",
                                          },
                                        })
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              text: "",
                                            },
                                            on: {
                                              click: _vm.toggleArchiveConf,
                                            },
                                          },
                                          [_vm._v("Archive")]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.viewType === "run"
                ? _c("RunStatusChip", {
                    attrs: {
                      status: _vm.runStatus,
                      "doc-id": _vm.item.id,
                      collection: _vm.collection,
                      "is-label": "",
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _c(
            "v-row",
            { staticClass: "pl-5 pt-4 pr-5" },
            [
              _c("span", { staticClass: "text-h5 font-weight-bold" }, [
                _vm._v(_vm._s(_vm.viewId)),
              ]),
              _c("v-spacer"),
              _vm.link
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text--secondary",
                      attrs: { text: "" },
                      on: { click: _vm.redirectToConfiguration },
                    },
                    [_vm._v("View current")]
                  )
                : _vm._e(),
              _vm.viewType === "status"
                ? [
                    !_vm.isViewer
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-3",
                            attrs: {
                              color: "complementary",
                              loading: _vm.isResettingWorkflow,
                            },
                            on: { click: _vm.resetWorkflowStatus },
                          },
                          [_vm._v(" Reset ")]
                        )
                      : _vm._e(),
                    _c("jobs-ratio", {
                      attrs: { jobs: _vm.item.jobs ? _vm.item.jobs : {} },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
      _vm.description
        ? _c("v-row", { staticClass: "pl-5 pr-5 pt-3 pb-3" }, [
            _c("span", { staticClass: "subheading" }, [
              _vm._v(_vm._s(_vm.description)),
            ]),
          ])
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.launchSnackBar.color,
            timeout: _vm.launchSnackBar.timeout,
          },
          model: {
            value: _vm.launchSnackBar.isVisible,
            callback: function ($$v) {
              _vm.$set(_vm.launchSnackBar, "isVisible", $$v)
            },
            expression: "launchSnackBar.isVisible",
          },
        },
        [_vm._v(" " + _vm._s(_vm.launchSnackBar.text) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.resetWorkflowStatusSnackBar.color,
            timeout: _vm.resetWorkflowStatusSnackBar.timeout,
          },
          model: {
            value: _vm.resetWorkflowStatusSnackBar.isVisible,
            callback: function ($$v) {
              _vm.$set(_vm.resetWorkflowStatusSnackBar, "isVisible", $$v)
            },
            expression: "resetWorkflowStatusSnackBar.isVisible",
          },
        },
        [_vm._v(" " + _vm._s(_vm.resetWorkflowStatusSnackBar.text) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }